import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from "../../components/project_page/ProjectPageWrapper"
import TextBlock from "../../components/project_page/TextBlock"
import Mobile3 from "../../components/project_page/Mobile3"
import Web1 from "../../components/project_page/Web1"
import Image1 from "../../components/project_page/Image1"
import ImageGrid from "../../components/project_page/ImageGrid"
import VideoWrap from "../../components/project_page/image_wrappers/VideoWrap"

import globalStyles from "../global.module.css"

const headerBackground = "#f2f2f2"
const navColor = "#222"
const Polls = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="PocketPolls"
      link={null}
      oneLiner="Checking polls as easily as the weather"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Problem"
          headline={`There's no way to quickly check political polls`}
          content={`Checking the state of political polls currently requires
            several steps, and is especially clunky on mobile. Polls are
            a valuable resource for people who want to be engaged in the
            political process. Their lack of accessiblity is a problem.
          `}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <TextBlock
          title="The Product"
          headline={`A simple mobile polling app`}
          content={`An app where people can easily check up to date US political
            polls including presidential, congressional and approval polls.`}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>

      <div className={`${globalStyles.marginBottomBig}`}>
        <div
          className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}
        >
          <div
            className={`${globalStyles.flexGrow} ${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}
          >
            <TextBlock
              title="Featured"
              headline={`Apple App Store`}
              content={
                <>
                  <p>
                    PocketPolls has been featured on the App Store as a top news
                    app and as a tool to stay up to date on the 2020 primaries
                    and general election.
                  </p>
                </>
              }
            />
          </div>
          <div
            className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}
          >
            <Image1 img={<Img fluid={data.body7.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body4.childImageSharp.fluid} />}
          two={<Img fluid={data.body5.childImageSharp.fluid} />}
          three={<Img fluid={data.body6.childImageSharp.fluid} />}
        />
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Polls

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/polls/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeader: file(
      relativePath: { eq: "projects/polls/header-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body1: file(relativePath: { eq: "projects/polls/phone1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body2: file(relativePath: { eq: "projects/polls/phone2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body3: file(relativePath: { eq: "projects/polls/phone3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body4: file(relativePath: { eq: "projects/polls/phone4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body5: file(relativePath: { eq: "projects/polls/phone5.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/polls/phone6.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body7: file(relativePath: { eq: "projects/polls/features.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
